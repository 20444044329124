<template>
    <v-app id="Layout">
        <v-app-bar app color="blue darken-3" dark clipped-left>
            <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
            <v-toolbar-title style="width: 100px;cursor:pointer" class="ml-0 pl-4" @click="$route.path!='/home'?$router.replace('/home'):null">闪云</v-toolbar-title>
            <div class="menu">
                <v-btn class="ma-2" text @click="$route.path!='/home'?$router.replace('/home'):null">
                    <v-icon left>mdi-home</v-icon>首页
                </v-btn>
                <v-btn class="ma-2" text @click="$router.push('/key')">
                    <v-icon left>mdi-shield-key</v-icon>密钥
                </v-btn>

                <v-btn class="ma-2" v-if="roles=='admin'" text @click="$router.push('/userList')">
                    <v-icon left>mdi-account-multiple</v-icon>用户管理
                </v-btn>
                <v-btn class="ma-2" v-if="roles=='admin'" text @click="$router.push('/adminNet')">
                    <v-icon left>mdi-format-list-bulleted-square</v-icon>平台虚拟网
                </v-btn>
            </div>
            <v-spacer></v-spacer>
            <!-- <v-btn color="warning" small @click="logout">登出</v-btn> -->

            <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <!-- <v-btn color="warning" small @click="logout">登出</v-btn> -->
                    <v-btn dark v-bind="attrs" v-on="on" text>
                        <v-icon left>mdi-apps</v-icon>设置
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="logout">
                        <v-list-item-title>登出</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/upPass">
                        <v-list-item-title>修改密码</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" app clipped>
            <v-list dense>
                <v-list-item @click="$route.path!='/home'?$router.replace('/home'):null">
                    <v-list-item-avatar>
                        <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{user.email}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item>
                        <v-select :items="networks" v-model="netId" item-text="name" item-value="id" @change="netChange" dense></v-select>
                    </v-list-item>
                    <v-list-item link v-for="(item,index) of actions" :key="index" :to="item.url">
                        <v-list-item-icon>
                            <v-icon small>{{item.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{item.text}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-list>
        </v-navigation-drawer>



        <v-main class="grey lighten-4 fill-height">
            <v-container>
                <router-view></router-view>
            </v-container>
        </v-main>
        <v-footer color="blue darken-3" app>
            <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
        </v-footer>
    </v-app>
</template>

<script>
    // @ is an alias to /src
    import { post, obj, clearCookie, setCookie, tips, getCookie } from '@/facade'

    export default {
        name: 'Layout',
        data() {
            return {
                drawer: null,
                user: {},
                item: 0,
                menus: [],
                form: {},
                roles: 'member',
                networks: [],
                actions: [
                    { text: '配置', url: '/netDetail', icon: 'mdi-pencil' },
                    { text: '设备', url: '/equipment', icon: 'mdi-desktop-classic' },
                    { text: '账号', url: '/accountList', icon: 'mdi-account' },
                    { text: '订单', url: '/orderList', icon: 'mdi-format-list-bulleted-square' },
                    { text: '节点', url: '/nodeGroup', icon: 'mdi-star' },
                ],
                netInfo: {},
                netId: ""
            }
        },

        async created() {
            const info = localStorage.getItem('netInfo')
            this.netInfo = JSON.parse(info);
            this.netId = this.netInfo.id
            const roles = localStorage.getItem('scene')
            console.log(roles)
            this.form.uid = roles == 'admin' ? 0 : obj.uid
            await this.getNetworkList();
            await this.getRoles();
        },

        methods: {
            // 获取虚拟网列表
            async getNetworkList() {
                const data = await post('/vnet/network/list', this.form)
                if (data.code == 'ok') {
                    this.networks = data.networks
                } else {
                    tips('error', data.message)
                }
            },
            /**获取用户信息 */
            async getRoles() {
                const data = await post('/user/user')
                if (data.code == 'ok') {
                    this.user = data.user
                    const roles = data.user.roles[0]
                    this.roles = roles
                    setCookie('roles', roles, 1);
                } else {
                    // tips('error', data.message)
                    this.$router.replace('/login')
                }
            },
            async logout() {
                const data = await post('/user/logout')
                if (data.code == 'ok') {
                    clearCookie('token');
                    clearCookie('uid');
                    localStorage.removeItem('netInfo')
                    obj.token = "";
                    obj.uid = "";
                    this.$router.replace({ path: "/login" })
                }
            },

            /**切换虚拟网 */
            netChange() {
                console.log(this.netId)
                const network = this.networks.filter(item => item.id == this.netId)
                localStorage.setItem('netInfo', JSON.stringify(network[0]))
                console.log('123-111')
                location.reload()
            }
        }
    }
</script>
<style scoped>
    .menu{
        display: flex;
        display: -webkit-flex;
        overflow:auto
    }
</style>